import { customFetch, customRequest } from '@/shared/utils'
import axiosClient from '@/shared/libs/axiosClient'
import { downloadXls } from '@/shared/utils/download-xls'
import { computeParams } from '@/shared/utils/to-params'
import {
    IEarningDto,
    IObserverBody,
    IObserverResponse,
    IPayoutDto,
    ISubAccountInfoDto,
} from '@/entities/cabinet'

enum API_URL {
    all = '/api/v1/sub_accounts/all/',
    updateReward = '/api/v1/sub_accounts/virtual_sub_accounts_update/',
    editWallet = '/api/v1/sub_accounts/sub_account_update/wallet_address/',
    create = '/api/v1/sub_accounts/create/',
    summary = '/api/v1/sub_accounts/summary/',
    earnings = '/api/v1/sub_accounts/earnings/',
    payouts = '/api/v1/sub_accounts/payouts/',
    stratumUrl = '/api/v1/sub_accounts/stratum_url/',
    statistics = '/api/v1/statistic/crypto_currency/',
    chart = '/api/v1/sub_accounts/observer/hashrate_chart/',
    earningExportXls = '/api/v1/sub_accounts/earnings/xls/',
    payoutExportXls = '/api/v1/sub_accounts/payouts/xls/',
    observerLinkAll = '/api/v1/sub_accounts/observer_link/all/',
    createObserverLink = '/api/v1/sub_accounts/observer_link/create/',
    deleteObserverLink = '/api/v1/sub_accounts/observer_link/delete/',
}

export const getSubAccounts = (): Promise<ISubAccountInfoDto[]> => {
    return customRequest
        .getItem<ISubAccountInfoDto[], any>(API_URL.all)
        .then((res) => res.data)
}

export const editSubAccountWallet = (body: {
    sub_account_id: number
    wallet_address: string
}) => {
    return customRequest.patch(API_URL.editWallet, body)
}

export const updateSubAccountRewardApi = (
    body: Partial<{
        sub_account_id: number
        virtual_sub_accounts: ISubAccountInfoDto['virtual_sub_accounts']
    }>
) => {
    return customRequest.post(API_URL.updateReward, body)
}

export const getSubAccountsSummary = (
    name: string,
    { signal }: { signal: any }
) => {
    return customRequest.getItem(
        API_URL.summary + `?sub_account_name=${name}`,
        '',
        { signal }
    )
}

export const getSubAccountsPayouts = (
    name: string,
    { signal }: { signal: any },
    params?: any
) => {
    return customRequest.getItem<IPayoutDto[], any>(
        API_URL.payouts +
            (params
                ? computeParams({ ...params, sub_account_name: name })
                : `?sub_account_name=${name}`),
        '',
        { signal: signal }
    )
}

export const getSubAccountsEarning = (
    name: string,
    { signal }: { signal: any },
    params?: any
) => {
    return customRequest.getItem<IEarningDto[], any>(
        API_URL.earnings +
            (params
                ? computeParams({ ...params, sub_account_name: name })
                : `?sub_account_name=${name}`),
        '',
        { signal }
    )
}

export const createSubAccount = (body: any) => {
    return customRequest.post(API_URL.create, body)
}

export const getSubAccountStratumUrls = (name: string) => {
    return customRequest.getItem(
        API_URL.stratumUrl + `?sub_account_name=${name}`
    )
}

export const createObserverLink = (body: IObserverBody) => {
    return customRequest.post(API_URL.createObserverLink, body)
}

export const deleteObserverLink = (body: { key: string }) => {
    return customRequest.delete(API_URL.deleteObserverLink, body)
}

export const getAllObserverLinks = (): Promise<IObserverResponse[]> => {
    return customRequest
        .getItem<IObserverResponse, any>(API_URL.observerLinkAll)
        .then((res) => res.data)
}

export const getStatistics = () => {
    return axiosClient.get(API_URL.statistics)
}

export const getSubAccountChart = (
    {
        sub_account_name,
        interval,
    }: {
        sub_account_name: string
        interval: string
    },
    signal?: any
) => {
    return customRequest.getItem(
        API_URL.chart +
            `?interval=${interval}&sub_account_name=${sub_account_name}`,
        '',
        { signal }
    )
}
export const exportEarningXls = (params: {
    sub_account_name: string
    start_date: string | null
    end_date: string | null
}) => {
    return customRequest
        .getItem(API_URL.earningExportXls + computeParams(params), '', {
            responseType: 'blob',
        })
        .then((response: any) => {
            downloadXls(response)
        })
}

export const exportPayoutXls = (params: {
    sub_account_name: string
    start_date: string | null
    end_date: string | null
}) => {
    return customRequest
        .getItem(API_URL.payoutExportXls + computeParams(params), '', {
            responseType: 'blob',
        })
        .then((response: any) => {
            downloadXls(response)
        })
}
