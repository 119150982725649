import { computeParams } from '@/shared/utils/to-params'
import { customRequest } from '@/shared/utils'
import {
    IEarningReferal,
    IInvitedUsersReferal,
    IReferalLink,
    IReferralDetail,
} from '@/entities/cabinet'

const enum API_URL {
    create = '/api/v1/referral_program/create/',
    links = '/api/v1/referral_program/all/',
    detail = '/api/v1/referral_program/issuer_detail/',
    invitedUsers = '/api/v1/referral_program/issuer_referral_users/',
    earnings = '/api/v1/referral_program/issuer_referral_earnings/',
}
export function createReferalLinkApi(body: {
    wallet_address: string
    pool_fee?: number
}) {
    if (body.pool_fee === undefined) {
        delete body.pool_fee
    }
    return customRequest
        .post<any, any>(API_URL.create, body)
        .then((res) => res.data)
}

export function listReferalLinkApi() {
    return customRequest
        .getItem<IReferalLink[], any>(API_URL.links)
        .then((res) => res.data)
}

export function referalLinkDetailApi() {
    return customRequest
        .getItem<IReferralDetail, any>(API_URL.detail)
        .then((res) => res.data)
}

export function referalInvitedUsers() {
    return customRequest
        .getItem<IInvitedUsersReferal[], any>(API_URL.invitedUsers)
        .then((res) => res.data)
}

export function referalEarningsApi(): Promise<IEarningReferal[]> {
    return customRequest
        .getItem<IEarningReferal[], any>(API_URL.earnings)
        .then((res) => res.data)
}
