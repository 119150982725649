import { createBrowserRouter } from 'react-router-dom'
import React, { Suspense } from 'react'
import { AuthLayout } from '@/app/layouts'
import WrapperLayout from '@/app/layouts/wrapper-layout'
import { SpinnerLoader } from '@/shared/ui/SpinnerLoader'
const MainPage = () => import('@/app/pages/main')
const LoginPage = () => import('@/app/pages/auth/login')
const AdminPage = () => import('@/app/pages/admin')
const AdminMainPage = () => import('@/app/pages/admin/main')
const HistoryPage = () => import('@/app/pages/admin/history')
const AdminReferralPage = () => import('@/app/pages/admin/referral')

const SuspenseWrapper = (callback: () => any) => {
    const LazyElement = React.lazy(callback)

    return (
        <Suspense fallback={<SpinnerLoader />}>
            <LazyElement />
        </Suspense>
    )
}

const router = createBrowserRouter([
    {
        path: '',
        element: <WrapperLayout />,
        children: [
            {
                path: '/',
                element: SuspenseWrapper(MainPage),
            },
            {
                path: '/auth',
                element: <AuthLayout />,
                children: [
                    {
                        path: 'login',
                        element: SuspenseWrapper(LoginPage),
                    },
                ],
            },
            {
                path: 'admin',
                element: SuspenseWrapper(AdminPage),
                children: [
                    {
                        path: '',
                        element: SuspenseWrapper(AdminMainPage),
                    },
                    {
                        path: 'history',
                        element: SuspenseWrapper(HistoryPage),
                    },
                    {
                        path: 'referal',
                        element: SuspenseWrapper(AdminReferralPage),
                    },
                ],
            },
        ],
    },
])
export { router }
