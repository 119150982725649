import style from './index.module.scss'
import {
    ADD_SUBACCOUNT,
    CURRENT_SUBACCOUNT_KEY,
    useSubAccount,
} from '@/entities/cabinet'
import React, { useContext, useState } from 'react'
import { GlobalContext } from '@/entities'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { CDropdown } from '@/shared/ui/cabinet'
import {
    setCurrentSubAccount,
    setCurrentSubAccountInfo,
    setDialogs,
} from '@/store/sub_accounts'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/store'
import { changeGlobalSubAccount } from '@/store/global'
import {
    CoinsComponent,
    ELANG,
    LanguageComponent,
    useTheme,
} from '@/features/global'
import { useTranslations } from 'use-intl'
import { DarkModeToggle } from '@/features'
import Logo from '@/shared/ui/Logo'

const enum URLS {
    observerLink = '/observer-link',
}

function Component({ subAccountName }: { subAccountName?: string }) {
    const { currentUser, currency } = useContext(GlobalContext)
    const { currentSubAccountInfo, subAccounts, currentSubAccount } =
        useSubAccount()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [showLangs, setShowLangs] = useState<boolean>(false)
    const i18n = useTranslations('cabinet.sidebar')
    const { currentTheme } = useTheme()
    const [showCoins, setShowCoins] = useState(false)

    const { lang } = useContext(GlobalContext)
    const subaccounts = useSelector(
        (state: RootState) => state.global.subaccounts
    )
    return (
        <header
            className={classNames(
                style.header,
                pathname.includes(URLS.observerLink)
                    ? style.header__observer
                    : ''
            )}
        >
            <div className={style.header__left}>
                <Link
                    to="/"
                    className={
                        pathname.includes(URLS.observerLink)
                            ? style.header__observer__logo
                            : style.header__left__logo
                    }
                >
                    {currentTheme === 'dark' ? (
                        <Logo />
                    ) : (
                        <img
                            src={require('@/shared/images/main_logo.png')}
                            width={130}
                            alt={'main logo'}
                        />
                    )}
                </Link>
            </div>
        </header>
    )
}

export const CHeader = React.memo(Component)
