import style from '@/features/cabinet/ui/cabinet-sidebar-component/index.module.scss'
import { Link, useLocation } from 'react-router-dom'
import Logo from '@/shared/ui/Logo'
import { setDialogs } from '@/store/sub_accounts'
import { LOGOUT_DIALOG } from '@/entities/cabinet'
import { Icon } from '@/shared/ui'
import classNames from 'classnames'
import { LogoutDialog, useTheme } from '@/features'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/store'
import { useTranslations } from 'use-intl'

const ITEMS = [
    {
        title: 'main',
        url: '/admin',
        type: 'dashboard',
    },
    {
        title: 'history',
        url: '/admin/history',
        type: 'earnings',
    },
    {
        title: 'payout',
        type: 'payout',
        url: '/admin/payout',
    },
    {
        title: 'referal',
        url: '/admin/referal',
        type: 'referal',
    },
]

const BOTTOM_ITEMS = [
    {
        title: 'logout',
        url: '/cabinet/logout',
        type: 'logout',
    },
]

export function AdminSidebarComponent() {
    const dialogs = useSelector((state: RootState) => state.subAccount.dialogs)
    const { pathname } = useLocation()
    const i18n = useTranslations('cabinet.sidebar')
    const { currentTheme } = useTheme()
    const dispatch = useAppDispatch()

    return (
        <aside className={style.sidebar}>
            <div className={style.sidebar__icon}>
                {currentTheme === 'dark' ? (
                    <Link to={'/'}>
                        <Logo />
                    </Link>
                ) : (
                    <Link to="/" className="logo">
                        <img
                            src={require('@/shared/images/main_logo.png')}
                            width={130}
                            alt={'main logo'}
                        />
                    </Link>
                )}
            </div>
            <div className={style.sidebar__content}>
                <div className={style.sidebar__content__wrapper}>
                    <div className={style.sidebar__content__navbar}>
                        {ITEMS.map((item, idx) => (
                            <Link
                                to={item.url}
                                key={idx}
                                className={
                                    item.url === pathname ? style.active : ''
                                }
                            >
                                <Icon
                                    type={item.type as any}
                                    color={
                                        currentTheme === 'dark' ? '#fff' : ''
                                    }
                                />
                                <span>{i18n(item.title)}</span>
                            </Link>
                        ))}
                    </div>
                    <div
                        className={classNames(
                            style.sidebar__content__bottomBar,
                            style.sidebar__content__navbar
                        )}
                    >
                        {BOTTOM_ITEMS.map((item, idx) => (
                            <Link
                                to={item.url}
                                key={idx}
                                onClick={(e) => {
                                    if (item.type === 'logout') {
                                        e.preventDefault()
                                        dispatch(
                                            setDialogs({
                                                key: LOGOUT_DIALOG,
                                                value: true,
                                            })
                                        )
                                    }
                                }}
                                className={
                                    item.url === pathname ? style.active : ''
                                }
                            >
                                <Icon type={item.type as any} />
                                <span>{i18n(item.title)}</span>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            {dialogs[LOGOUT_DIALOG] && <LogoutDialog />}
        </aside>
    )
}
