import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    createReferalLinkApi,
    IEarningReferal,
    IInvitedUsersReferal,
    IReferalLink,
    IReferralDetail,
    listReferalLinkApi,
    referalEarningsApi,
    referalInvitedUsers,
    referalLinkDetailApi,
} from '@/entities/cabinet'
import {
    CREATE_REFERAL,
    DETAIL_REFERAL,
    EARNINGS_REFERAL,
    GET_ALL_REFERAL,
    INVITED_USERS_REFERAL,
} from '@/store/referal/variables'

export interface IApiKeyState {
    loading: boolean
    referals: Array<IReferalLink>
    earnings: IEarningReferal[]
    detail: IReferralDetail | null
    error: string | null
    invitedUsers: IInvitedUsersReferal[]
}
const initialState: IApiKeyState = {
    loading: true,
    referals: [],
    earnings: [],
    error: null,
    detail: null,
    invitedUsers: [],
}

const getAllReferal$ = createAsyncThunk(GET_ALL_REFERAL, async () => {
    const response = await listReferalLinkApi()
    return Promise.resolve(response)
})

const getDetailReferal$ = createAsyncThunk(DETAIL_REFERAL, async () => {
    const response = await referalLinkDetailApi()
    return Promise.resolve(response)
})

const createReferal$ = createAsyncThunk(
    CREATE_REFERAL,
    async (body: { wallet_address: string; pool_fee?: number }) => {
        const response = await createReferalLinkApi(body)
        return Promise.resolve(response)
    }
)

const getReferalInvitedUsers$ = createAsyncThunk(
    INVITED_USERS_REFERAL,
    async () => {
        const response = await referalInvitedUsers()
        return Promise.resolve(response)
    }
)
const getReferalEarnings$ = createAsyncThunk(EARNINGS_REFERAL, async () => {
    const response = await referalEarningsApi()
    return Promise.resolve(
        response.sort((a, b) => {
            return new Date(b.earnings_for_str) > new Date(a.earnings_for_str)
                ? 1
                : -1
        })
    )
})

export const referalKeySlice = createSlice({
    name: 'reference',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDetailReferal$.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllReferal$.fulfilled, (state, { payload }) => {
            state.referals = payload.map((referral: IReferalLink) => ({
                ...referral,
                wallet_address:
                    referral.wallet_address.substring(0, 20) +
                    ' ' +
                    referral.wallet_address.substring(
                        20,
                        referral.wallet_address.length
                    ),
            }))
            if (payload.length === 0) {
                state.loading = false
            }
        })
        builder.addCase(getDetailReferal$.fulfilled, (state, { payload }) => {
            state.detail = payload
            state.loading = false
        })
        builder.addCase(createReferal$.rejected, (state, payload) => {
            state.error = 'generate.error'
        })
        builder.addCase(getReferalEarnings$.fulfilled, (state, { payload }) => {
            state.earnings = payload
        })
        builder.addCase(
            getReferalInvitedUsers$.fulfilled,
            (state, { payload }) => {
                state.invitedUsers = payload
            }
        )
    },
})

export const referalReducer = referalKeySlice.reducer

export {
    getAllReferal$,
    getReferalEarnings$,
    createReferal$,
    getReferalInvitedUsers$,
    getDetailReferal$,
}
