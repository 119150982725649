import { computeParams } from '@/shared/utils/to-params'
import {
    IWorker,
    IWorkersReqParams,
    IWorkerStatistics,
} from '@/entities/cabinet'
import { customRequest } from '@/shared/utils'

const enum API_URL {
    workers = '/api/v1/sub_accounts/workers/',
    statistics = '/api/v1/sub_accounts/statistics/',
}
export function getSubAccountWorkers(params?: IWorkersReqParams) {
    return customRequest
        .getItem<IWorker[], any>(
            API_URL.workers,
            params ? computeParams(params) : ''
        )
        .then((res) => res.data)
}

export function getSubAccountStatistics(params?: IWorkersReqParams) {
    return customRequest
        .getItem<IWorkerStatistics, any>(
            API_URL.statistics,
            params ? computeParams(params) : ''
        )
        .then((res) => res.data)
}
